import React from 'react'
import formatDate from '../../../utils/formatDate'
import {
  Description,
  articleWrapClasses,
  postedDateClasses,
  titleClasses,
} from './ArticleCard'

const EventCard = ({ date, title, location, url, external, description }) => (
  <article className={articleWrapClasses}>
    <div className={postedDateClasses}>{formatDate(date)}</div>
    <div>
      <a
        className={titleClasses}
        href={url}
        target={external ? '_blank' : null}
      >
        {title}
        {location && (
          <>
            <span className="text-green-3"> / </span>
            <span className="text-grey">{location}</span>
          </>
        )}
      </a>
      {/* Hack to prevent rendering description when it only includes an empty p tag from Prismic */}
      {description && description.length > 10 && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </div>
  </article>
)

export default EventCard
