import React, { Component } from 'react'
import classNames from 'classnames'
import WysiwygContent from '../../../components/General/WysiwygContent'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'
import ArticleCard from '../../General/Cards/ArticleCard'
class ContentList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFilter:
        props.filters && props.filters[0] && props.filters[0].value,
    }

    this.clickHandler = this.clickHandler.bind(this)
  }

  componentDidMount() {
    function getUrlVars() {
      let vars = {}
      window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
          vars[key] = value
        }
      )
      return vars
    }

    let urlVar = getUrlVars()['filter']
    if (urlVar) this.clickHandler(getUrlVars()['filter'])
  }

  clickHandler = (item) => {
    this.setState((state) => ({ selectedFilter: item }))
  }

  render() {
    const {
      mantelData,
      seo,
      ListComponent = ArticleCard,
      list = [],
      afterListHtml,
      filters,
    } = this.props

    const contentListContainer = `
      mx-auto
      max-w-md
      lg:max-w-lg
      xl:max-w-xl
    `

    const listComponents = () => {
      let filteredList = list

      if (filters && this.state.selectedFilter) {
        filteredList = list.filter((item) => {
          if (this.state.selectedFilter === 'all') return true
          if (item.type === this.state.selectedFilter) return true
          return false
        })
      }

      return filteredList.map((item, idx) => (
        <div className="mb-5" key={'list-item' + idx}>
          <ListComponent {...item} />
        </div>
      ))
    }

    return (
      <PageWrap bg={2} seo={seo}>
        <Mantel {...mantelData} />
        {filters ? (
          <div
            className="
            flex
            mx-auto
            max-w-md
            lg:max-w-lg
            xl:max-w-xl
            mb-4
          "
          >
            <div className="mr-2">Filters: </div>
            {filters.map((filter, idx) => (
              <div
                key={'filter' + idx}
                className={classNames(
                  'font-sans text-sm hover:text-green-3 hover:underline cursor-pointer mr-2',
                  {
                    'text-green-3 underline':
                      filter.value === this.state.selectedFilter,
                  }
                )}
                onClick={() => this.clickHandler(filter.value)}
              >
                {filter.name}
              </div>
            ))}
          </div>
        ) : undefined}
        <div className={contentListContainer}>
          {listComponents()}
          {afterListHtml && (
            <div className="mt-8">
              <WysiwygContent content={afterListHtml} />
            </div>
          )}
        </div>
        {/* <div className={ loadMoreContainer }>
        <JumpButton>Load more</JumpButton>
      </div> */}
      </PageWrap>
    )
  }
}

export default ContentList
