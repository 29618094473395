import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import formatDate from '../../../utils/formatDate'

const articleWrapClasses = `
  font-sans
  text-charcoal
`

const postedDateClasses = `
  uppercase
  text-xs
  font-sans
  text-black
  flex-no-shrink
  lg:mr-5
  lg:pt-1
  mb-2
  lg:mb-0
`

const titleClasses = `
  text-2xl
  font-sans
  leading-tight
  no-underline
  hover:underline
  text-bold
  text-black
  visited:text-teal
`

const attributionClasses = `
  font-sans
  text-sm
  mt-1
`
const authorClasses = `
  font-sans
  text-green-3
  font-bold
  no-underline
  hover:underline
`

const Description = styled.div`
  ${tw`
    mt-3
    font-light
    font-sans
    leading-normal
  `}

  p:not(:last-child),
  img:not(:last-child) {
    ${tw`
      mb-3
    `}
  }

  a {
    ${tw`
      text-green-3
      font-bold
    `}
  }
`

const ArticleCard = ({
  date,
  title,
  url,
  author,
  authorLink,
  description,
  external,
}) => (
  <article className={articleWrapClasses}>
    {date ? (
      <p
        className="
          text-green-3
          text-xs
          font-extrabold
          font-sans
          pb-1"
      >
        {formatDate(date)} |
      </p>
    ) : null}
    <div>
      <a
        className={titleClasses}
        href={url}
        target={external ? '_blank' : null}
      >
        {title}
      </a>
      {author && (
        <div className={attributionClasses}>
          <span>By </span>
          {authorLink ? (
            <a className={authorClasses} href={authorLink}>
              {author}
            </a>
          ) : (
            <span className="font-sans text-green-3 font-bold">{author}</span>
          )}
        </div>
      )}
      {/* Hack to prevent rendering description when it only includes an empty p tag from Prismic */}
      {description && description.length > 10 && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </div>
  </article>
)

export default ArticleCard
export { Description, articleWrapClasses, titleClasses, postedDateClasses }
