import React from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../../utils/graphql'
import { localizePath } from '../../../../config/i18n'

import ContentList from '../../../components/Templates/ContentList'
import EventCard from '../../../components/General/Cards/EventCard'
import NoTranslation from '../../../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

function transformData (data, prismicData) {
  data.list = prismicData.edges.map(({ node: { data } }) => ({
    title: data.title.text,
    date: data.date,
    location: data.location,
    description: data.description.html,
    url: data.signup_link.url,
    external: true
  }))

  return data
}

const EventsPage = ({ data, pageContext }) => {
  const { prismicEvents, allPrismicEvent } = data
  const page = prismicEvents
  if (!page) return (<NoTranslation></NoTranslation>)
  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      parent: {
        path: localizePath(pageContext.locale, '/work-with-us'),
        text: 'work with us'
      }
    }),
    list: [],
    afterListHtml: page.data.content.html,
    ListComponent: EventCard
  }
  return (
    <ContentList { ...transformData(hydratedData, allPrismicEvent) } />
  )
}

export default withPreview(EventsPage)

export const pageQuery = graphql`
  query EventsBySlug( $locale: String! ) {
    prismicEvents( lang: { eq: $locale } ) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        content {
          html
        }
      }
    }
    allPrismicEvent(
      filter: {lang: {eq: $locale}},
      sort: {fields: [data___date], order:ASC}
    ) {
      edges {
        node {
          data {
            title {
              text
            },
            date,
            location,
            description {
              html
            },
            signup_link {
              url,
              target
            }
          }
        }
      }
    }
  }
`
